<template>
    <div>
        <ToolsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Field Measurement</h1>

            <img :src="`${$cdiVars.BASE_URL}/images/v3/cdi_field_measure.jpg`" class="img-responsive img_content_right">

            <p>Sometimes it's necessary to do a field measure to verify an existing curb's dimensions. If this applies to you, you're in luck. We've provided an easy-to-follow guide that will help you through the process.</p>
            <p>Please fill out the attached form, making sure to note which opening is the return, and which opening is the supply.</p>
            <p>And remember, your existing curb may not exactly match this configuration, but as long as you fill this sheet completely we'll figure out the rest.</p>

            <ul class="list_content">
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_measure_instructions.pdf`" target='_new'>Download Field Measure Instructions</a></li>
                <li><a :href="`${$cdiVars.BASE_URL}/download/3910149`" target='_new'>Download Field Measure Drawing</a> (Need to be signed in to download)</li>
                <li><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_measure_form_editable.pdf`" target='_new'>Download Field Measure Form</a></li>
            </ul>

            <h3>CDI requires the following information for a curb verification</h3>
            <ol class="list_content">
                <li>Outside dimensions of the curb <strong>Do not measure the RTU</strong></li>
                <li>Return and supply sizes. Also label the Return and Supply on the drawing.</li>
                <li>Locations of the return and supply from the outside of the curb</li>
                <li>Brand name and model # of the existing RTU</li>
            </ol>

            <h3>Remember</h3>
            <ul class="list_content">
                <li>CDI over sizes YOUR dimensions 1-1/2", so please supply actual field dimensions</li>
                <li>It is better to make a curb adapter 2" too large than 2" too small</li>
                <li>You can never supply CDI with too much information. Some other information that is helpful is any object that may be in the way such as: walls, pipes, other units, skylights, equipment rails, etc.</li>
                <li>Pictures are great</li>
            </ul>
        </main>
    </div>
</template>

<script>
import ToolsNav from '@/components/content/ToolsNav.vue'
export default {
    name: "FieldMeasure",
    components: {
        ToolsNav,
    },
    created() {
        this.$appStore.setTitle(['CDI Field Measurement']);
    },
}
</script>

<style scoped>

</style>