<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul class="nav nav-pills nav-stacked affix">
                <li class="cdi_tab"><router-link to="/credit_app">Credit Application</router-link></li>
                <li class="cdi_tab"><router-link to="/ductcal">Duct Calculator</router-link></li>
                <li class="cdi_tab"><router-link to="/field_measure">Field Measurement</router-link></li>
                <li class="cdi_tab"><router-link to="/install_instructions">Installation Instructions</router-link></li>
                <li class="cdi_tab"><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_transit_map_2021.pdf`">Transit Map</a></li>
                <li class="cdi_tab"><router-link to="/termsandconditions">Terms of Sales</router-link></li>
                <li class="cdi_tab"><router-link to="/mobile">Mobile</router-link></li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "ToolsNav",
    }
</script>

<style scoped>

</style>